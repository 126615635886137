
export interface SPatient {
  patientIdentifier: string;
}

export const usePatientStore = defineStore('patient', {
  state() {
    return { patient: {} as SPatient };
  }
});
